<template>
  <div>
    <div class="Garage_Kits">
      <img class="banner" src="../../assets/img/main/icon/Garage_Kits_bg.jpg" alt="">
      <van-uploader class="sendUp":after-read="afterRead">
        <div>+</div>
      </van-uploader>

      <div class="Garage_Kits_lb">


        <div class="swiper my-swipe">
          <!-- Additional required wrapper -->
          <div class="swiper-wrapper">

            <div class="swiper-slide" v-for="item in imgLis" :key="item.id">
              <div class="left" @click="shows2(item,0)">
                <div>
                  <img v-if="item.status==0" :src="'http://maplays.net/img/uploadPic/statue20240326/'+item.imga_url" alt="">
                  <div class="sbw" v-if="item.status==1"><img src="../../assets/img/main/icon/shP.png" alt=""></div>

                </div>
              </div>
              <div class="right" @click="shows2(item,1)">
                <div>
                  <img v-if="item.imgb_url!=1" :src="'http://maplays.net/img/uploadPic/statue20240326/'+item.imgb_url" alt="">
                  <div v-if="item.imgb_url==1">制作中</div>
                </div>
              </div>
              <div class="sbw2">
                <div>发布者：{{item.user_id}}</div>
                <div>发布时间：{{item.uptime}}</div>
              </div>
            </div>

            <!-- Slides -->
            <div class="swiper-slide">
              <div class="left" @click="shows1(1)">
                <div>
                  <img src="../../assets/img/main/icon/s1.jpg" alt="">
                </div>
              </div>
              <div class="right" @click="shows1(2)">
                <div>
                  <img src="../../assets/img/main/icon/zhubo5.jpg">
                </div>
              </div>
              <div class="sbw2">
                <div>发布者：135****9968</div>
                <div>发布时间：03.29 13:45</div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="left" @click="shows1(3)">
                <div>
                  <img src="../../assets/img/main/icon/s2.jpg" alt="">
                </div>
              </div>
              <div class="right" @click="shows1(4)">
                <div>
                  <img src="../../assets/img/main/icon/zhubo4.jpg">
                </div>
              </div>
              <div class="sbw2">
                <div>发布者：136****6037</div>
                <div>发布时间：03.29 15:22</div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="left" @click="shows1(5)">
                <div>
                  <img src="../../assets/img/main/icon/s3.jpg" alt="">
                </div>
              </div>
              <div class="right" @click="shows1(6)">
                <div>
                  <img src="../../assets/img/main/icon/zhubo1.jpg">
                </div>
              </div>
              <div class="sbw2">
                <div>发布者：134****5426</div>
                <div>发布时间：03.30 11:37</div>
              </div>
            </div>

          </div>
        </div>


      </div>



    </div>
    <van-dialog v-if="shstatus==0" v-model="show" width="78%" height='10rem'
                :closeOnClickOverlay="true"
                :show-cancel-button="false"
                :show-confirm-button="false"
    >
      <img style="width: 98%;margin: 1.2rem auto;display: block;" :src="src" />
    </van-dialog>
  </div>
</template>

<script>
import Swiper from "swiper/swiper-bundle";
import "swiper/swiper-bundle.min.css";
import leaseApi from '../../utils/leaseApi'
import {Toast} from "vant";
export default {
  name: "shouBan",
  components: {
    Swiper,
  },
  data(){
    return{
      show:false,
      shstatus:0,
      src:'',
      form:{
        user_id:'',
        upStatue:''
      },
      imgLis:[  //status:0 //通过  1审核中
        // {
        //   imga_url:require(''),
        //   imgb_url:require(''),
        //   status:0 ,//通过
        // },
        // {
        //   imga_url:require(''),
        //   imgb_url:require(''),
        //   status:0 ,//通过
        // },
        // {
        //   imga_url:require(''),
        //   imgb_url:require(''),
        //   status:0 ,//通过
        // },
      ]
    }
  },
  methods:{
    async afterRead(file) {
      this.form.upStatue = file
      // 此时可以自行将文件上传至服务器
      // console.log(this.form.user_id,file);
      let formDat = new FormData();
      formDat.append('user_id',this.form.user_id);
      formDat.append('upStatue',file.file);
      // console.log(formDat)
      // console.log('@@@@',this.form)
      const {code,msg} = await leaseApi.statueUpload(formDat);
      if(code===0){
        Toast(msg);
        // this.$message({
        //   message:msg,
        // })
        // console.log(1111)
        this.initPicData();
      }else if(code===-1){
        Toast('你已经上传过图片了');
        // this.$message({
        //   message:'你已经上传过图片了',
        // })
      }else {
        Toast('请联系客服');
        // this.$message({
        //   message:'请联系客服',
        // })
      }

    },
    async initPicData(){
      const res = await leaseApi.selectStatue();

      res.data.forEach((item)=>{
        // console.log(item)
        item.uptime = this.initTime(item.uptime);
        item.user_id = this.initP(item.user_id)
      })
      // console.log(res.data)
      this.imgLis = res.data;
    },
    initTime(t){
      let dateObj = new Date(t)// 转换为本地时间格式
      // console.log(dateObj)
      // console.log(dateObj.getMonth())
      let month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // 月份加1是因为月份是从0开始计数的
      let day = dateObj.getDate().toString().padStart(2, '0');
      let hours = dateObj.getHours().toString().padStart(2, '0');
      let minutes = dateObj.getMinutes().toString().padStart(2, '0');

      let formattedTime = `${month}.${day} ${hours}:${minutes}`;

      return formattedTime
    },
    initP(phone){
        let ph1= phone.substring(0,3);
        let ph3= phone.substring(phone.length-4);
        let sbwf = `${ph1}****${ph3}`;
        return sbwf
    },
    initswiper(){

     new Swiper('.swiper',{
        loop: true,
        speed:8000,
        slidesPerView : 3,
        direction: 'vertical', // 垂直切换选项
        spaceBetween: 30,
       autoplay: {
         delay: 0, // 自动切换的时间间隔
         disableOnInteraction: false,
       },
       observer: true,
       observeParents: true,
      });
    },
    shows1(i){
      // console.log(222,i)
      this.shstatus = 0;
      this.show = true;
      let imgArr = [
          require('../../assets/img/main/icon/s1.jpg'),
          require('../../assets/img/main/icon/zhubo5.jpg'),
          require('../../assets/img/main/icon/s2.jpg'),
          require('../../assets/img/main/icon/zhubo4.jpg'),
          require('../../assets/img/main/icon/s3.jpg'),
          require('../../assets/img/main/icon/zhubo1.jpg'),
      ]
      if(i===1){
        this.src = imgArr[0]  ;
      }else if(i===2){
        this.src = imgArr[1]  ;
      }else if(i===3){
        this.src = imgArr[2]  ;
      }else if(i===4){
        this.src = imgArr[3]  ;
      }else if(i===5){
        this.src = imgArr[4]  ;
      }else if(i===6){
        this.src = imgArr[5]  ;
      }
    },
    open(item,index){
      this.show = true;
      // console.log('###',item,index)
      if(index===0){
        this.src = `http://maplays.net/img/uploadPic/statue20240326/${item.imga_url}` ;
      }else if(index===1){
        this.src = `http://maplays.net/img/uploadPic/statue20240326/${item.imgb_url}` ;
      }
    },
    shows2(item,index){
      // console.log(item.imgb_url)
      if(item.status===1){
        this.shstatus = 1;
      }else {
        this.shstatus = 0;
        // console.log(111)
        this.open(item,index)
      }

    }
  },
  mounted() {
    this.initPicData();
    // this.form.user_id = window.localStorage.getItem('phone');
    // // console.log(this.form.user_id)
    // if(this.form.user_id!=null||this.form.user_id!=''){  //13455606666
    //   let ph1= this.form.user_id.substring(0,3);
    //   let ph3= this.form.user_id.substring(this.form.user_id.length-4);
    //   this.sbwf = `${ph1}****${ph3}`
    // }

    // let nums = '13458609450'
    //   let ph1= nums.substring(0,3);
    //   // let ph3= nums.substring(7,11);
    //   let ph3= nums.substring(nums.length-4);
    // console.log(ph1+ph3)

    this.$nextTick(()=>{
      this.initswiper();
    })
  }
}
</script>

<style lang="less" scoped>
.Garage_Kits{
  img{
    width: 100%;
    display: block;
  }
  .banner{
    position: relative;
    z-index: 222;
  }
  /deep/.van-uploader__wrapper{
  display: block;
  }
  .Garage_Kits_lb{
    margin-top: -2.2rem;
    background: #FCFBF4;
    .my-swipe{
      height: 19.2rem;
      width: 100%;
      z-index: 555;
      .swiper-wrapper{
        //top: 20rem;
        transition-timing-function: linear !important;
        -webkit-transition-timing-function: linear !important;
        -moz-transition-timing-function: linear !important;
        -ms-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        .swiper-slide {
          height: 32%!important;
          margin-bottom: 0!important;
          .sbw2{
            font-size: 0.3rem;
            color: #000;
            position: absolute;
            bottom: 0.4rem;
            left: 0.5rem;
          }
          .left{
            position: absolute;
            left: 0rem;
            top: 0.5rem;
            width: 5.53rem;
            height: 4.56rem;
            background: url("../../assets/img/main/icon/Garage_Kits_box_pic.png") no-repeat;
            background-size: 100%;
            overflow: hidden;
            >div{
              width: 4.74rem;
              height: 3.49rem;
              margin: 0.5rem 0.4rem 0rem;
              overflow: hidden;
              .sbw{
                display: flex;
                height: 100%;
                align-items: center;
                img{
                  width: 66%;
                  margin: 0 auto;
                }
              }

            }
          }
          .right{
            background: url("../../assets/img/main/icon/Garage_Kits_box_pic.png") no-repeat;
            background-size: 100%;
            position: absolute;
            right: 0rem;
            top: 1.6rem;
            width: 5.53rem;
            height: 4.56rem;
            overflow: hidden;
            z-index: 1;
            >div{
              width: 4.74rem;
              height: 3.49rem;
              margin: 0.5rem 0.4rem 0rem;
              overflow: hidden;
              >div{
                color: #fff;
                font-size: 0.8rem;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
              }
              img{
                margin-top: -1.4rem;
              }
            }
          }
        }
      }


    }

  }
  .sendUp{
    font-size: 1rem;
    position: fixed;
    left: 0.4rem;
    bottom: 0.4rem;
    border: 0.1rem #bbb dashed;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    z-index: 666;
  }
}
</style>
